import { Component, createRef } from 'react';

class LazyLoadedImage extends Component {
	constructor({ width, height }) {
		super();

		this.state = {
			shouldRender: false,
			loaded: false,
		};

		this.elementRef = createRef();
		this.aspectRatio = Math.round(width / height).toString();
	}

	isInView() {
		const element = this.elementRef.current;

		if (!element) return;

		const { top } = element.getBoundingClientRect();
		const { innerHeight } = window;

		// Add a buffer to the innerHeight to prevent flickering
		return top < innerHeight * 1.5;
	}

	updateShouldRender(e) {
		const { shouldRender } = this.state;

		const newShouldRender = this.isInView();

		// No need to re-render if the state hasn't changed
		if (shouldRender !== newShouldRender) {
			this.setState({ shouldRender: newShouldRender });
		}

		// this.removeScrollListener();
	}

	handleLoad() {
		const { src } = this.props;
		const { onImageLoad } = this.props;

		const element = this.elementRef.current;

		if (onImageLoad) {
			onImageLoad(element);
		}

		this.setState(
			{
				loaded: true,
			},
			() => {
				element.src = src;
				this.updateShouldRender();
			}
		);
	}

	// Image loading

	createImage() {
		const { src } = this.props;

		// Create a new image element to pre-load the image
		const imageLoader = new Image();
		this.imageLoader = imageLoader;

		this.addImageLoadListener();
		imageLoader.src = src;
	}

	// Event Listeners

	removeScrollListener() {
		document
			.querySelector('main')
			.removeEventListener('scroll', this.updateShouldRender.bind(this));
	}

	removeImageLoadListener() {
		if (!this.imageLoader) return;

		this.imageLoader.removeEventListener(
			'load',
			this.handleLoad.bind(this)
		);
	}

	addScrollListener() {
		document
			.querySelector('main')
			.addEventListener('scroll', this.updateShouldRender.bind(this));
	}

	addImageLoadListener() {
		if (!this.imageLoader) return;

		this.imageLoader.addEventListener('load', this.handleLoad.bind(this));
	}

	// Mounting

	componentWillUnmount() {
		const { notLazy } = this.props;

		if (notLazy) return;

		this.removeScrollListener();
		this.removeImageLoadListener();
	}

	componentDidMount() {
		const { notLazy } = this.props;

		if (notLazy) return;

		this.addScrollListener();
		this.createImage();
	}

	render() {
		const {
			width,
			height,
			placeholderSrc,
			src,
			className,
			notLazy,
			loadingClassName,
			loadedClassName,
			...rest
		} = this.props;
		const { shouldRender, loaded } = this.state;
		const aspectRatio = this.aspectRatio;

		// Only render an actual image if there is a placeholder or the image has loaded, else render a placeholder div
		return notLazy ? (
			<img
				width={width}
				height={height}
				ref={this.elementRef}
				className={className}
				src={src} // If the image is in view, render the actual image
				{...rest}
			/>
		) : placeholderSrc || loaded ? (
			<img
				width={width}
				height={height}
				ref={this.elementRef}
				className={`animate-fade-in ${className} ${
					shouldRender ? loadedClassName : loadingClassName
				}`}
				src={shouldRender ? src : placeholderSrc} // If the image is in view, render the actual image
				{...rest}
			/>
		) : (
			<div
				ref={this.elementRef}
				style={{
					aspectRatio,
				}}
				className={`bg-white w-full ${className} ${
					shouldRender ? loadedClassName : loadingClassName
				}`}
				{...rest}
			/>
		);
	}
}

export default LazyLoadedImage;
