import { makeRequest } from 'modules/web/api';
import { useState } from 'react';

export default () => {
	const [files, setFiles] = useState([]);
	const [description, setDescription] = useState([]);

	const submit = async () => {
		console.log(files);
		await makeRequest('/userUpload', {
			files,
			description,
		});

		window.location = '/home';
	};

	const handleFile = (file) => {
		return new Promise((resolve) => {
			let reader = new FileReader(); // built in API

			reader.addEventListener('load', (e) => {
				const base64 = e.target.result;

				resolve({
					file: base64,
					name: file.name,
				});
			});

			reader.readAsDataURL(file);
		});
	};

	const handleFilesUpload = async (e) => {
		let files = e.target.files; // JS FileList object

		const newFiles = [];

		for (const file of files) {
			newFiles.push(await handleFile(file));
		}

		newFiles = newFiles.filter((x) => x.file && x.name);

		setFiles(newFiles);
	};

	return (
		<div className='flex flex-col gap-5 place-items-center m-5'>
			<input type='file' multiple onInput={handleFilesUpload} />
			<input
				className='max-w-xs p-2 bg-fill text-background'
				placeholder='Enter description'
				onChange={(e) => setDescription(e.target.value)}
				type='text'
			/>
			<button className='bg-fill text-background p-2' onClick={submit}>
				Upload
			</button>
		</div>
	);
};
