import LazyLoadedImage from 'Components/shared/LazyLoadedImage';
import { getMetadata } from 'modules/utils/metadata';
import { Component, createRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
	images,
	lowresImages,
	collections,
	nonBorderedImages,
} from 'modules/images';
import Theme from 'contexts/Theme';
import shuffle from 'modules/utils/shuffle';
import { getElementOffset } from 'modules/utils/offset';

const tags = ['Automotive', 'Portrait', 'Street', 'Landscape', 'Astro'];

class PhotoGallery extends Component {
	constructor() {
		super();

		this.state = {
			metadata: {},
			activeTags: {},
		};

		this.tagsRef = createRef();
	}

	fetchMetadata = async () => {
		const metadata = await getMetadata();

		this.setState({ metadata, loaded: true });
	};

	componentDidMount() {
		this.fetchMetadata();

		const first = collections[0];
		let shuffled = collections.slice(1);

		shuffle(shuffled);
		this.setState({ collections: [first, ...shuffled] });

		let offset;

		const main = document.querySelector('main');

		main.addEventListener('scroll', (e) => {
			const tagsElement = this.tagsRef.current;
			if (!tagsElement) return;

			if (!offset) offset = getElementOffset(tagsElement).top;

			if (window.innerWidth > 640) {
				if (main.scrollTop + 100 > offset) {
					tagsElement.style.position = 'fixed';
					tagsElement.style.zIndex = '99999';
					tagsElement.style.top = '100px';
				} else {
					tagsElement.style.position = 'absolute';
					tagsElement.style.zIndex = '0';
					tagsElement.style.top = '0px';
				}
			}
		});
	}

	toggleTag = (tag) => {
		const activeTags = {
			...this.state.activeTags,
			[tag]: !this.state.activeTags[tag],
		};

		this.setState({
			activeTags,
		});
	};

	render() {
		const { metadata, loaded, activeTags, collections } = this.state;
		const { theme } = this.props;

		if (!loaded) {
			return;
		}

		let imagesMap = [];

		for (const [index, collection] of Object.entries(collections)) {
			const collectionImages = images[collection];
			const collectionLowresImages = lowresImages[collection];
			const collectionMetadata = metadata[collection];
			const collectionNonBorderedImages = nonBorderedImages[collection];
			const path = `./${collectionMetadata?.coverImage}`;

			const fileName = path.split('/').pop();

			const img = collectionImages(path);
			const nonBorderedImg = collectionNonBorderedImages(`./${fileName}`);
			const lowresImg = collectionLowresImages(`./${fileName}`);

			let visible = true;

			for (const tag in activeTags) {
				if (
					activeTags[tag] &&
					!collectionMetadata?.tags?.includes(tag)
				) {
					visible = false;

					break;
				}
			}

			if (!visible) continue;

			imagesMap.push(
				<Link
					key={path}
					to={`/photo-collection/${collection}`}
					className={`${
						collectionMetadata[fileName]?.orientation ===
						'landscape'
							? 'col-[span_3_/_auto]'
							: ''
					}  `}>
					<div className='group overflow-clip relative'>
						<div className='absolute peer w-full h-1/2 bottom-0 left-0 z-[999999]' />
						<LazyLoadedImage
							notLazy={index === 0}
							width={collectionMetadata[fileName]?.width}
							height={collectionMetadata[fileName]?.height}
							src={theme === 'light' ? nonBorderedImg : img}
							className={`shadow-lg w-full h-full transition-all duration-300 sm:peer-hover:blur-[2px] blur-0 ${
								collectionMetadata[fileName]?.fit === false
									? ''
									: 'object-cover'
							}`}
							alt={path}
							placeholderSrc={lowresImg}
						/>
						<div
							className={`absolute flex flex-row justify-center place-content-center my-10 w-full bottom-0 opacity-0 sm:peer-hover:opacity-100 translate-all duration-300 z-10`}>
							<span className='text font-standard text-textContent'>
								{collectionMetadata.name}
							</span>
						</div>
						<div className='w-full h-full bg-gradient-to-b from-transparent to-[rgba(0,0,0,0.95)] absolute top-0 left-0 opacity-0 sm:peer-hover:opacity-100 transition-all duration-300 z-0' />
						<div className='bg-[url("https://framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png")] sm:peer-hover:opacity-[0.02] opacity-0 inset-[-200%] w-[400%] h-[400%] absolute transform-[translateX(-10%) translateY(10%) translateZ(0px)]' />
					</div>
				</Link>
			);

			// Everyhing after the first image is shuffled
		}

		const tagsMap = tags.map((tag) => {
			const active = activeTags[tag];

			return (
				<span
					key={tag}
					className={`text-textContent text-xs sm:text-sm rounded-full font-standard transition-all duration3-00 text-nowrap ${
						active ? 'bg-slate-500' : 'bg-[rgba(50,50,50,0.7)]'
					} px-5 py-2 cursor-pointer select-none`}
					onClick={() => {
						this.toggleTag(tag);
					}}>
					{tag}
				</span>
			);
		});

		return (
			<div className='m-5 mb-16 group sm:relative'>
				<div
					ref={this.tagsRef}
					className='absolute z-[9999] sm:z-0 sm:h-10 max-w-full flex-wrap sm:max-w-full w-full px-5 sm:px-0 left-0 bottom-0 sm:top-0 opacity-100 sm:opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-row gap-2 place-items-center justify-center pb-5 '>
					{tagsMap}
				</div>
				<div className='grid grid-cols-3 gap-2 sm:gap-[25px] auto-rows-auto grid-flow-dense group-hover:translate-y-[50px] duration-300 transition-all'>
					{imagesMap}
				</div>
			</div>
		);
	}
}

export default (props) => {
	const theme = useContext(Theme);

	return <PhotoGallery {...props} theme={theme} />;
};
