import PortraitPhoto from 'assets/images/hawaii-690.jpg';
import LazyLoadedImage from 'Components/shared/LazyLoadedImage';

export default () => {
	return (
		<div className='p-5 flex flex-col gap-5 w-full h-full mb-10'>
			<LazyLoadedImage
				src={PortraitPhoto}
				alt='Me'
				width={6000}
				height={4000}
				className='brightness-[150%]'
			/>
			<div className='flex flex-col gap-2'>
				<h1 className='font-bold text-2xl'>Caden Marinozzi 2x1</h1>
				<span className='text-xl'>
					A Bay Area based full-stack software engineer, graphic
					designer and photographer, dedicated to his crafts.
				</span>
			</div>
		</div>
	);
};
